<template>
  <!-- 合同文件制作页面(自行发起盖章) -->
  <div style="height: 100%">
    <backtitle lable="合同文件管理" />
    <div class="contractFileMake">
      <!-- 左侧主要内容 -->
      <div class="left">
        <!-- 顶部业务数据 -->
        <titleTemplate :name="title">
          <template slot="operation">
            <span style="padding-right: 25px">业务编号：{{ statusCode }}</span>
          </template>
        </titleTemplate>
        <div class="item makecontent">
          <!-- 合同文件操作 -->
          <title-name-slot title="合同文件">
            <template slot="operation">
              <div class="makeTitle">
                <div  class="tipsDiv">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content=" 当电子签署人为'-'时,则表示签署方为平台,系统自动进行静默签署"
                    placement="top-start"
                  >
                    <i class="iconfont iconwenhao"></i>
                  </el-tooltip>
                </div>
                <base-button
                  label="批量生成合同文件"
                  style="float: right; margin-left: 10px"
                  @click.prevent="getHtgCfgitem()"
                  icon="iconfont iconshengcheng"
                  v-if="!canSubmitFlag"
                />
                <base-button
                  label="批量选择签署人"
                  style="margin-left: 10px; float: right"
                  @click.prevent="openSign()"
                  icon="iconfont iconrenyuantiaozheng"

                />
                 <base-button
                  label="增加文件类型"
                  style="float: right; margin-left: 10px"
                  @click.prevent="addFile"
                  icon="iconfont icona-wenjian2"
                  v-if="!canSubmitFlag"
                />
                <!-- <base-button
                  label="还款计划管理"
                  style="margin-left: 10px; float: right"
                  @click.prevent="openRepaymentPlan()"
                  icon="iconfont iconhuankuan"
                  v-if="capitalSideName === '中小担'||capitalSideName === '中小企'"
                /> -->
                  <!-- <base-button
                  label="股东会决议有效性管理"
                  style="float: right; margin-left: 10px"
                  @click.prevent="showMeetingConference()"
                  icon="iconfont iconshengcheng"
                  v-if="resultValidFlag==='1'"
                /> -->
                 <base-button
                  label="批量下载合同文件"
                  style="float: right; margin-left: 10px"
                  @click.prevent="downAll()"
                  icon="iconfont iconxiazai"
                />
                <!-- <div class="addbtn" style="margin-right: 10px">
                  <div class="addbutton" @click="addFile">
                    <i class="el-icon-plus"></i>
                  </div>
                </div> -->
              </div>
            </template>
          </title-name-slot>
          <!-- 合并表格 -->
          <!-- spanMethod: objectSpanMethod, -->
          <base-table
            :columns="columns"
            :showPage="false"
            :tableAttrs="{
              data: tableData,
              stripe: false,
              border: true,
            }"
            :isCaculateHeight="false"
            ref="tableData"
            :webPage="true"
            class="table"
            :selectedRows.sync="selectedRows"
          >
            <template slot="index" slot-scope="scope">{{
              scope.$index + 1
            }}</template>
              <!-- 文件材料题名 -->
            <template slot="documentName" slot-scope="scope">
              <template v-if="scope.row.addtype">
                <el-select
                  v-model="tableData[scope.$index].documentId"
                  @change="
                    (val) => changeDocument(val, scope.row, scope.$index)
                  "
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in scope.row.option"
                    :key="item.keyId"
                    :label="item.documentName"
                    :value="item.documentId"
                  />
                </el-select>
              </template>
              <template v-else>{{ scope.row.documentName }}</template>
            </template>
            <!-- 模板文件按钮组 -->
            <template slot="templateFile" slot-scope="scope">
              <template-file-button-group
                :canSubmitFlag="canSubmitFlag"
                :scope="scope"
                @Upload="Upload"
                @deleteTemplate="deleteTemplate"
              />
            </template>
             <!-- 文件列按钮组 -->
            <template slot="fileList" slot-scope="scope">
              <file-button-group
                v-for="(item, index) in scope.row.fileListDetailVOS"
                :key="index"
                :canSubmitFlag="canSubmitFlag"
                :item="item"
                :index="index"
                :scope="scope"
                @previewShow="previewShow"
                @Upload="Upload"
                @deleteTemplate="deleteTemplate"
                @dowload='dowload'
                @onlineEdit='onlineEdit'
              />
            </template>
              <!-- 操作按钮组 -->
            <template slot="action" slot-scope="scope">
              <contaract-button-group
                :canSubmitFlag="canSubmitFlag"
                :scope="scope"
                @previewShow="previewShow"
                @getHtgCfgitem="getHtgCfgitem"
                @Upload="Upload"
                @deleteTemplate="deleteTemplate"
                @appendFile="appendFile"
                @viewResume='viewResume'
              />
            </template>
            <!-- 是否需要盖章 -->
            <template slot="isSign" slot-scope="scope">
              <el-checkbox
              v-model="scope.row.isSign"
              :true-label="'1'"
              :false-label="'0'"
               @change="changeIsSign(scope.row,scope.$index)"
              ></el-checkbox>
            </template>
          </base-table>
         <!-- 基础资料 -->
          <base-data-list
            :dataList="baseTableData"
            @previewShow="previewShow"
            @viewResume="viewResume"
            :businessId='$route.query.businessId'
            @getFilelist="getFilelist"
            :type="1"
            ref="baseTableData"
          ></base-data-list>
        </div>
      </div>
      <!-- 右侧进度 -->
      <div class="right">
        <titleTemplate name="申请意见"> </titleTemplate>
            <base-from
              :showBtns="false"
              :componentList="approvalFromConfig"
              :formAttrs="{
                model: form,
                labelWidth: '50px',
                labelPosition: 'left',
              }"
              class="formStyle"
              ref="fromdata"
            ></base-from>
             <div class="submit">
            <base-button
              label="保存"
              type="primary"
              @click.prevent="onContractSave"
            />
            <base-button
              label="提交"
              type="primary"
              @click.prevent="onContractSubmit"
            />
          </div>
        <audit-timeline
          :dataList="finReviewOpinionInfoVO"
          class="auditTimeline"
          :name="'审批详情'"
          :auditInfo="{ fileMakeName: '进行中' }"
        />
      </div>
    </div>
    <!-- 在线编辑 -->
    <online-edit-dialog
    :onlineEditVisible.sync="onlineEditVisible"
    :count="editCount"
    :fileId="editFileId"
    :fileType='editFileType'
    :fileEditData='fileEditData'
    >
    </online-edit-dialog>
    <!-- 文件预览 -->
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="count" />
    <!-- 文件处理履历 -->
    <document-history
    :docHistoryVisible.sync="docHistoryVisible"
    :docHistoryData='docHistoryData'
    :count="docHistoryCount"
    :fileTypeLists='fileTypeLists'
    ></document-history>
    <!-- 合同补录项 -->
    <recording-dialog
      :visible.sync="visible"
      :selctfile="selctfile"
      @handHtSave="handHtSave"
      @handHtCommit="handHtCommit"
      :publicFormConfig='publicFormConfig'
      ref="recordingDialog"
      :queryParasArr="queryParasArr"
    />
    <!-- 批量选择电子章签署人 -->
    <select-signatory
      :signShow.sync="signShow"
      :tableData="signTableData"
      :sealAdmins="sealAdmins"
      :chosePass.sync='chosePass'
    />
     <!--新版本  批量选择电子章签署人 -->
    <new-select-signatory
      :signShow.sync="newSignShow"
      :tableData="signTableData"
      :sealAdmins="sealAdmins"
      :chosePass.sync='chosePass'
    ></new-select-signatory>
    <!-- 修改还款计划 -->
    <repayment-plan @repaymentPlanSubmit='repaymentPlanSubmit' :planShow.sync='planShow' :financeAmount='financeAmount' :repaymentData='repaymentData' :repaymentPlanContract='repaymentPlanContract' />
    <meeting-conference :meetingConferenceShow.sync='meetingConferenceShow' @changeValidTimeUnit='changeValidTimeUnit'/>
  </div>
</template>
<script>
/* eslint-disable */
import documentHistory from './components/document-history.vue'
import onlineEditDialog from './components/online-edit-dialog.vue'
import PreView from '@/components/pre-view/pre-view.vue'
import MeetingConference from './components/meeting-conference.vue'
import termOfValidity from '../financing-decision-manage/components/term-of-validity.vue'
import SelectSignatory from './components/select-signatory.vue'
// import SupplementFileButtonGroup from './components/supplement-file-button-group.vue'
import TemplateFileButtonGroup from './components/template-file-button-group.vue'
import FileButtonGroup from './components/file-button-group.vue'
import ContaractButtonGroup from './components/contaract-button-group.vue'
// import preViewDialog from './components/pre-view-dialog.vue'
import { allSupportFileTypes } from '@/components/pre-view/pre-view.js'
import recordingDialog from './components/recording-dialog.vue'
import backtitle from '@/pages/business/components/backtitle.vue'
import { uploadcontractApi, fileAPi, downAPi } from '@/api/fileApi'
import { fileListApi, contractApi, signApi, replenishApi } from '@/api/businessApi'
import titleNameSlot from '@/pages/dashboard/components/title-name-slot.vue'
import titleTemplate from '@/pages/business/components/titleTemplate.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import newSelectSignatory from './components/new-select-signatory.vue'
import AuditTimeline from '../components/auditTimeline.vue'
import { getManager } from '@/api/sys/user'
import BaseFrom from '@/components/common/base-form/base-form.vue'
import { editFileByOffice } from '@/plugin/web-office/index'
import BaseDataList from '@/pages/business/contract-file-make/components/base-data-list.vue'

import {
  dataTable,
  dataTables,
  fileTable,
  formConfig,
  formConfig1,
  approvalFrom

} from './utils/config.js'
import Storage from '@/utils/storage'
import { downFile } from '@/utils/devUtils'
import RepaymentPlan from './components/repayment-plan.vue'
export default {
  name: 'contractFileMake',
  components: {
    SelectSignatory,
    baseButton,
    BaseTable,
    titleTemplate,
    titleNameSlot,
    backtitle,
    recordingDialog,
    AuditTimeline,
    onlineEditDialog,
    ContaractButtonGroup,
    TemplateFileButtonGroup,
    FileButtonGroup,
    RepaymentPlan,
    MeetingConference,
    newSelectSignatory,
    PreView,
    documentHistory,
    BaseDataList,
    BaseFrom
    // SupplementFileButtonGroup
  },
  data () {
    return {
      publicFormConfig: [],
      financeAmount: '',
      repaymentPlanContract: [],
      repaymentData: [],
      planShow: false,
      capitalSideName: '',
      signShow: false,
      signTableData: [],
      canSubmitFlag: false,
      count: 0,
      fileId: '',
      fileType: '',
      previsible: false,
      onlineEditVisible: false,
      options: [],
      queryParas: {},
      form: {},
      tableData: [],
      baseTableData: [],
      basicsTableData: [],
      selectedRows: [],
      basicsSelectedRows: [],
      fileData: [],
      visible: false,
      htconfig: [],
      selctfile: [],
      finReviewOpinionInfoVO: [],
      queryParasArr: [],
      sealAdmins: [],
      chosePass: true,
      meetingConferenceShow: false,
      resultValidFlag: '0',
      sealRequiredOptions: [
        { value: '1', label: '是' },
        { value: '0', label: '否' }
      ],
      newSignShow: false, // 打开新 选择签章人
      // 全选内容
      checkAll: false,
      checkedCitiesL: [],
      isIndeterminate: false,
      // 在线编辑内容
      editFileId: '', // 编辑文件id
      editFileType: '', // 编辑文件类型
      editCount: 0,
      docHistoryVisible: false, // 文件履历弹窗
      docHistoryCount: 0,
      docHistoryData: {},
      fileTypeLists: [], // 文件类型选项
      // newFileId: '', // 新的文件id
      fileEditData: {}, // 在线编辑数据
      optionsManager: []
    }
  },
  watch: {
    tableData: {
      handler (val) {
        if (val) {
          this.getChecked()
        }
      },
      deep: true
    }
  },
  computed: {
    tags () {
      return JSON.parse(sessionStorage.getItem('routerList'))
    },
    uploadcontractApi () {
      return uploadcontractApi.upload()
    },
    title () {
      return '供应商：' + Storage.getLocal('contractInfo').gysCompanyName
    },
    statusCode () {
      return Storage.getLocal('contractInfo').businessNo
    },
    productId () {
      return Storage.getLocal('contractInfo').finaaceProductId
    },
    capitalSideProductId () {
      return Storage.getLocal('contractInfo').capitalSideProductId
    },
    contractApi () {
      return contractApi
    },
    api () {
      return fileListApi
    },
    signApi () {
      return signApi
    },
    columns () {
      return dataTable(this)
    },
    basicsColumns () {
      return dataTables(this)
    },
    fileTable () {
      return fileTable(this)
    },
    formConfig () {
      return formConfig(this)
    },
    formConfig1 () {
      return formConfig1(this)
    },
    approvalFromConfig () {
      return approvalFrom(this)
    }
  },

  methods: {
    // 合同制作岗
    getRiskManagerOptions () {
      getManager({ roleCode: 'htzzg_code' }).then(res => {
        const arr = res.data.filter(item => item.userId !== this.$store.state.user.userInfo.userId)
        this.optionsManager = arr.map(item => {
          return { riskManagerId: item.userId, riskManagerName: item.nickName, riskUserName: item.userName }
        })
      })
    },
    // 文件履历查看
    // 获取文件最新数据
    async getFileDetail (keyId) {
      return await fileListApi.fileDetailId({detailId: keyId})
    },
  // 文件履历查看
    viewResume (row) {
      this.docHistoryData = row
      this.filterFileLists()
      this.docHistoryCount++
      this.docHistoryVisible = true
    },
    // 筛选当前的文件类型
    filterFileLists () {
      let arr = []
      this.tableData.forEach(item => {
        if(item.documentId) {
          arr.push({ documentId: item.documentId, documentName: item.documentName })
        }
      })
      this.$refs.baseTableData.tableData.forEach(item => {
        if(item.documentId) {
          arr.push({ documentId: item.documentId, documentName: item.documentName })
        }
      })
      this.fileTypeLists = arr
    },
    // 在线编辑
    async  onlineEdit (row, index, i) {
      const detail = await this.getFileDetail(row.keyId)
      if (detail && detail.data) {
        const res = await fileAPi.fileCopy({ fileId: detail.data.fileId })
        row.fileId = detail.data.fileId ? detail.data.fileId : ''
        row.fileName = detail.data.fileName ? detail.data.fileName : ''
      }
      row.documentName = this.tableData[index].documentName
      this.fileEditData = JSON.parse(JSON.stringify(row))
      console.log(this.fileEditData)
      editFileByOffice(this.fileEditData)
      // 复制文件
      // const res = await fileAPi.fileCopy({ fileId: row.fileId })
      // row.fileId = res.data.keyId ? res.data.keyId : ''
      // row.fileName = res.data.fileName ? res.data.fileName : ''
      // row.documentName = this.tableData[index].documentName
      // this.fileEditData = JSON.parse(JSON.stringify(row))
      // // 编辑复制的文件
      // this.editFileId = res.data.keyId
      // this.editFileType = res.data.fileName.substr(res.data.fileName.lastIndexOf('.') + 1, 4)
      // this.onlineEditVisible = true
      // this.$nextTick(() => {
      //   this.editCount++
      // })
    },
    // 回显选中状态
    getChecked () {
      this.checkedCitiesL = []
      if (this.tableData.length > 0) {
        this.tableData.forEach((item) => {
          if (item.isSign === '1') {
            this.checkedCitiesL.push(item)
          }
        })
        const checkedCount = this.checkedCitiesL.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.tableData.length
        this.checkAll = checkedCount === this.tableData.length
      }
    },
    // 表格行内多选框点击
    checkboxchange (val, index) {
      if (val === '1') {
        this.checkedCitiesL.push(this.tableData[index])
      } else {
        this.checkedCitiesL.pop()
      }
      const checkedCount = this.checkedCitiesL.length

      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableData.length
      this.checkAll = checkedCount === this.tableData.length
      this.getChecked()
    },
    // 全选
    updateAllSelected (val) {
      this.checkAll = !this.checkAll
      this.checkedCitiesL = val
        ? JSON.parse(JSON.stringify(this.tableData))
        : []
      const newarr = this.tableData
      // 页面设置全选
      newarr.forEach((item, index) => {
        this.$set(newarr[index], 'isSign', val ? '1' : '0')
      })
      const subData = newarr.map(item => {
        return { finFileListInfoKeyId: item.keyId, isSignStatus: item.isSign, businessId: item.businessId, documentType: item.documentType }
      })
      fileListApi.updateIsSignStatus(subData).then(res => {
        console.log(res.data)
      })
      this.getChecked()
    },
    // 改变是否盖章
    changeIsSign (row, index) {
      const data = [{ finFileListInfoKeyId: row.keyId, isSignStatus: row.isSign, businessId: row.businessId, documentType: row.documentType }]
      fileListApi.updateIsSignStatus(data).then(res => {
        this.checkboxchange(row, index)
      })
    },
    // 批量下载
    downAll () {
      const filtKeyIds = []
      this.tableData.forEach((item) => {
        if (item.fileListDetailVOS && item.fileListDetailVOS.length > 0) {
          item.fileListDetailVOS.forEach((elem) => {
            filtKeyIds.push(elem.currentFileId)
          })
        }
      })

      if (filtKeyIds.length > 0) {
        downAPi
          .batchDownload({ keyIds: filtKeyIds, fileName: '全部附件' })
          .then((res) => {
            downFile(res)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 显示股东会决议有效性管理
    showMeetingConference () {
      this.meetingConferenceShow = true
    },
    // 提交还款计划
    repaymentPlanSubmit (data) {
      console.log(data, 'data')
      this.api.resetRepayPlans(data).then(res => {
        if (res.data) {
          this.success('修改成功')
          this.planShow = false
        }
      })
    },

    // 打开修改还款计划
    openRepaymentPlan () {
      this.api.getRepayPlans({ businessId: this.$route.query.businessId }).then(res => {
        if (res.data) {
          this.financeAmount = res.data.financeAmount.toString()
          this.repaymentData = res.data.finRepayPlans
          this.repaymentPlanContract = res.data.finRepayPlans[0].finRepayContracts
          this.repaymentPlanContract.forEach((item) => {
            item.keyId = item.repayContractId
            item.contractName = item.finContractName
          })
          this.repaymentData.forEach((item) => {
            if (item.repayContractId) {
              item.contractRepayment = item.repayContractId.split(',')
            }
          })
        }
        this.planShow = true
      })
    },
    // 合并数据
    filterData (arr = [], innerKey, orderKey) {
      const res = []
      const map = {}
      arr.forEach((item) => {
        const key = item[innerKey]
        item[orderKey] = 0
        if (!map[key]) {
          map[key] = [item]
        } else {
          map[key].push(item)
        }
      })
      for (const key in map) {
        const list = map[key]
        list[0][orderKey] = list.length
        res.push(...list)
      }
      return res
    },
    // 第二次
    TowFilterData (arr = [], innerKey, orderKey) {
      const res = []
      const map = {}
      arr.forEach((item) => {
        const key = item[innerKey]
        item[orderKey] = 0
        if (!map[key]) {
          map[key] = [item]
        } else {
          map[key].push(item)
        }
      })
      for (const key in map) {
        let newKey = ''
        let num = 0
        const list = map[key]
        map[key].forEach((item, index) => {
          if (index === 0) {
            newKey = item.positionConfigId
          } else {
            if (newKey === item.positionConfigId) {
              list[index][orderKey] = 1
              num++
            }
          }
        })
        list[0][orderKey] = list.length - num
        res.push(...list)
      }
      return res
    },
    newFilterData (arr = [], innerKey, orderKey) {
      const res = []
      const map = {}
      arr.forEach((item) => {
        const key = item[innerKey]
        item[orderKey] = 0
        if (!map[key]) {
          map[key] = [item]
        } else {
          map[key].push(item)
        }
      })
      for (const key in map) {
        const list = map[key]
        list[0][orderKey] = list.length
        if (innerKey === 'finFileListDetailId') {
          res.push(
            ...this.TowFilterData(
              list,
              'signerConfigId',
              'signerConfigIdRowSpan'
            )
          )
        } else {
          res.push(...list)
        }
      }
      return res
    },
    // 批量选择签署人
    async openSign () {
      // 合同文件必须全部生成后才可以选择电子章签署人
      let pass = false
      // let isSignFalge = false
      let documentName = ''
      console.log(this.$store.state.sys.dictMaps)
      console.log(this.tableData, 'tableData')
      this.tableData.forEach((item) => {
        console.log(item.fileListDetailVOS, !!item.fileListDetailVOS, 'item.fileListDetailVOS')
        if (item.fileListDetailVOS && item.fileListDetailVOS.length !== 0) {
          item.fileListDetailVOS.forEach((ele) => {
            if (ele.fileId === '0') {
              pass = true
              documentName = item.documentName
            }
          })
        } else if (item.fileListDetailVOS.length === 0) {
          pass = true
          documentName = item.documentName
        }
      })
      if (pass) {
        this.warning('请先生成' + documentName)
        return false
      }
      // 判断基础资料文件是否上传
      // this.$refs.baseTableData.tableData.forEach(item => {
      //   if (item.fileListDetailVOS && item.fileListDetailVOS.length !== 0) {
      //     item.fileListDetailVOS.forEach((ele) => {
      //       if (ele.fileId === '0' || !ele.fileId) {
      //         pass = true
      //         documentName = item.documentName
      //       }
      //     })
      //   } else if (item.fileListDetailVOS.length === 0) {
      //     pass = true
      //     documentName = item.documentName
      //   }
      // })
      // if (pass) {
      //   this.warning('请先选择' + documentName)
      //   return false
      // }
      // 必须有一个需要盖章
      const isSignFalge = this.tableData.some(item => item.isSign === '1')
      let isSignFalge1 = false 
      this.$refs.baseTableData.tableData.forEach(ele => {
        if(ele.fileListDetailVOS && !isSignFalge1) {
          isSignFalge1 = ele.fileListDetailVOS.some(item => item.isSign === '1')
        }
      })
      if (!isSignFalge && !isSignFalge1) {
        this.warning('必须有一个文件类型，为需要盖章')
        return false
      }
      const data = await this.signApi.getSignConfig({
        businessId: this.$route.query.businessId,
        batchNum: Storage.getLocal('contractInfo').batchNum || null
      })
      data.data.forEach((item) => {
        if (item.sealAdmins && item.sealAdmins.length > 0) {
          let pass = false
          item.sealAdmins.forEach((elem) => {
            if (elem.keyId === item.sealAdminId) {
              pass = true
            }
          })
          if (!pass) {
            item.sealAdminId = ''
          }
        } else {
          item.sealAdminId = ''
        }
      })

      this.signTableData = this.filterData(
        data.data,
        'finFileListId',
        'finFileListNameRowSpan'
      )
      this.signTableData = this.newFilterData(
        data.data,
        'finFileListDetailId',
        'finFileListDetailNameRowSpan'
      )
      this.signTableData.forEach((item, index) => {
        if (
          item.sealAdmins &&
          item.sealAdmins.length === 1 &&
          item.signerConfigIdRowSpan !== 0
        ) {
          item.sealAdminId = item.sealAdmins[0].keyId
        }
      })
      // this.signShow = true
      this.newSignShow = true
    },
    // 股东会决议 选择签章人后处理其他股东签章人候选项
    deleteArray (key, num) {
      this.sealAdmins.forEach((item, index) => {
        if (index !== num) {
          const newArr = []
          item.forEach((ele) => {
            if (ele.keyId !== key) {
              newArr.push(ele)
            }
          })
          this.$set(this.sealAdmins, index, newArr)
        }
      })
      // this.tableData.forEach((item) => {
      //   if (item.selectFlag) {
      //     if (item.selectIndex) {
      //       if (item.sealAdmins) {
      //         const newSealAdmins = []
      //         item.sealAdmins.forEach((elem) => {
      //           if (key !== elem.keyId) {
      //             newSealAdmins.push(elem)
      //           }
      //         })
      //         item.sealAdmins = newSealAdmins
      //       }
      //     }
      //   }
      // })
    },
    // 新增删除股东协议签署方
    insertOrDeleteShareholder (row) {
      let type = 1
      if (row.selectIndex === 1) {
        // 为第一项时
        type = 0
      }
      // type 0 新增  1删除
      const data = {
        status: type,
        ...row
      }
      this.api.insertOrDeleteShareholder(data).then((res) => {
        this.getFilelist()
      })
    },
    // 表格合并规则
    objectSpanMethod ({ row, column }) {
      // if (this.suppliersSealType === 0) {
      //   // 鲜章无合并
      //   return this.tableData
      // }
      if (
        column.property === 'documentName' ||
        column.property === 'uplaodUserName' ||
        column.property === 'uploadTime' ||
        column.property === 'templateFile' ||
        column.property === 'action'
      ) {
        const _row = row.documentNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 修改电子章签署人
    saveSigner (row) {
      const data = {
        businessId: row.businessId,
        data: [
          {
            keyId: row.signerInfoId,
            sealAdminId: row.sealAdminId
          }
        ]
      }
      fileListApi.saveSigner(data).then((res) => {
        if (res.data) {
          this.success('修改成功')
          this.getFilelist()
        }
      })
    },
    // 下载文件
    async dowload (data) {
      const detail = await this.getFileDetail(data.keyId)
      const fileId = (detail.data && detail.data.fileId) ?detail.data.fileId:data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 数组按指定元素分组
    sortClass (sortData, name) {
      const groupBy = (array, f) => {
        const groups = {}
        array.forEach((o) => {
          const group = JSON.stringify(f(o))
          groups[group] = groups[group] || []
          groups[group].push(o)
        })
        return Object.keys(groups).map((group) => {
          return groups[group]
        })
      }
      const sorted = groupBy(sortData, (item) => {
        return item[name] // 返回需要分组的对象
      })
      return sorted
    },
    // 预览模板文件
    async previewShow (data) {
      const detail = await this.getFileDetail(data.keyId)
      if(detail && detail.data) {
        data.fileId = detail.data.fileId
        data.fileName = detail.data.fileName
      }
      this.fileId =
        !data.pdfFileId || data.pdfFileId === '0'
          ? data.fileId
          : data.pdfFileId
      this.fileType = data.fileName ? data.fileName.split('.').pop() : ''
      this.fileType =
        !data.pdfFileId || data.pdfFileId === '0' ? this.fileType : 'pdf'
      this.fileType = this.fileType.toLowerCase()
      if (!this.fileType || !allSupportFileTypes.includes(this.fileType)) {
        this.warning(`不能预览的文件类型：[${this.fileType}]`)
        return
      }
      // this.previsible = true
      this.count++
      // this.$nextTick(() => {

      // })
    },
    // 选取合同文件-材料题名
    changeDocument (item, data, index) {
      console.log(item, data)
      let state = true
      this.tableData.forEach((ele, i) => {
        if (i !== index) {
          if (ele.documentIds === item || ele.documentId === item) {
            state = false
          } else {
            this.$set(ele, 'documentIds', ele.documentId)
          }
        }
      })
      if (!state) {
        this.$set(data, 'documentId', '')
        this.$set(data, 'documentIds', '')
        return this.warning('材料题名不能相同')
      } else {
        const params = {
          businessId: this.$route.query.businessId,
          documentId: data.documentId
        }
        fileListApi.getTemplate(params).then(res => {
          this.$set(data, 'documentIds', item)
          const [obj] = this.options.filter((it) => it.documentId === item)
          this.$set(data, 'documentName', obj.documentName)
          this.$set(data, 'documentType', obj.documentType)
          this.$set(data, 'isAdd', true)
          this.$set(data, 'templateFileId', res.data.templateFileId)
          this.$set(data, 'isGenerate', obj.isGenerate)
          this.$set(data, 'signed', obj.signed)
          this.$set(data, 'isSign', obj.isSign)
        })
      }
    },
    // 删除文件
    deleteTemplate (row, type, index, i) {
      if (row.addFile) {
        this.tableData[index].fileListDetailVOS.splice(i, 1)
        return
      }
      console.log(row, type, index, i)
      this.$confirm(type === 4 ? '是否删除当前项?' : '是否删除文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 构造参数
          // type:1 删除模板文件 2 删除已上传的合同文件 3 删除补充文件
          const data = {
            businessId: row.businessId || this.$route.query.businessId,
            documentId: row.documentId,
            keyId: row.keyId,
            keyIdF: row.keyIdF,
            documentType: row.documentType
          }
          // 添加合同文件还没有保存数据
          // if (row.addtype) {
          //   this.tableData.splice(index, 1)
          //   // 记录用户已新增的补充文件数量
          //   Storage.setSession('fileDataList', this.fileData)
          //   return
          // }
          // 追加文件时删除没有保存数据

          this.deleteFile(type, data)
        })
        .catch(() => {})
    },
    // 删除文件
    deleteFile (type, data) {
      const api = type === 1 ? contractApi : fileListApi
      // // 直接删除没有上传文件的
      // if (row.fileListDetailVOS[i].fileId === '0') {
      //   this.tableData[index].fileListDetailVOS.splice(i, 1)
      //   return
      // }
      if (type === 4) {
        // 删除当前行合同文件项
        api.deleteFileContracts([data.keyId]).then((res) => {
          if (res.data) {
            this.success('删除成功')
            this.getFilelist('start')
          } else {
            this.error('删除失败')
          }
        })
      } else if (type === 2) {
        api.deleteFile([data.keyIdF], '0').then((res) => {
          if (res.data) {
            this.success('删除成功')
            this.getFilelist()
          } else {
            this.error('删除失败')
          }
        })
      } else {
        api.delete(data).then((res) => {
          if (res.data) {
            this.success('删除成功')
            this.getFilelist()
            if (type === 3) {
              Storage.setSession('fileDataList', this.fileData)
            }
          } else {
            this.error('删除失败')
          }
        })
      }
    },
    // 追加文件
    appendFile (row, type, index) {
      console.log(row, 'row222')
      const [data] = row.fileListDetailVOS
        ? row.fileListDetailVOS.filter((item) => item.addFile)
        : [null]
      if (data) {
        return this.warning('请先上传文件')
      }
      if (!row.fileListDetailVOS) {
        this.$set(row, 'fileListDetailVOS', [])
      }
      row.fileListDetailVOS.push({ fileId: '0', fileName: '', addFile: true })
    },
    async Upload (param, row, type, index) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize > 0) {
        return this.warning('文件需小于10M')
      }
      const arr = param.file.name.split('.')
      const suffix = arr[arr.length - 1]
      let lite = ['docx']
      if (type !== 1) {
        lite = [
          'doc',
          'docx',
          'xlsx',
          'xls',
          'pdf',
          'png',
          'jpg',
          'gif',
          'bmp'
        ]
      }
      if (this.canSubmitFlag) {
        lite = ['doc', 'docx', 'pdf']
      }
      const flag = lite.some((val) => {
        return suffix.toLowerCase() === val
      })
      if (!row.documentId) {
        return this.warning('请先选择文件材料题名')
      }
      if (!flag) {
        if (this.canSubmitFlag) {
          this.warning('请上传doc,docx,pdf格式文件')
          return
        }
        if (type === 1) {
          this.warning('请上传docx格式文件')
        } else {
          this.warning('请上传doc,docx,xlsx,xls,pdf,png,jpg,gif,bmp格式文件')
        }
        return
      }
      const res = await this.UploadFile(param)
      const data = {
        businessId: row.businessId || this.$route.query.businessId,
        documentId: row.documentId,
        documentName: res.fileName,
        templateId: res.keyId,
        keyId: row.keyId,
        batchNum: Storage.getLocal('contractInfo').batchNum || null
      }
      // type:1 模板文件上传 2 合同文件上传 3 其他资料上传
      // 构造参数
      if (type === 1) {
        data.documentNameNew = row.documentName
      }
      if (type === 2) {
        data.keyId = row.keyIdF
        data.fileId = data.templateId
        data.documentName = row.documentName
        data.fileName = res.fileName
        data.documentType = row.documentType
        data.templateId = row.keyId
      } else if (type === 3) {
        data.businessId = this.$route.query.businessId
        const [obj] = this.options.filter(
          (item) => item.documentId === row.documentId
        )
        data.fileId = res.keyId
        data.documentName = obj.documentName
        data.fileName = res.fileName
        data.documentType = row.documentType
      }

      this.fileSending(type, data, row.addtype)
    },
    // 上传文件
    fileSending (type, data, addtype) {
      const api = type === 1 ? contractApi : fileListApi
      if (type === 2) {
        // 合同文件上传
        api.UploadDetailLmplementFile([data]).then((res) => {
          if (res.data) {
            this.success('文件上传成功')
            this.getFilelist(addtype ? 'start' : '')
          }
        })
      } else {
        api.UploadLmplementFile([data]).then((res) => {
          if (res.data) {
            this.success('文件上传成功')
            this.getFilelist()
          }
        })
      }
    },
    // 调用文件服务上传文件
    async UploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 时间处理
    formatTime (val, type) {
      if (val) {
        const time = new Date(val)
        if (type === 'yyyy 年 MM 月 dd 日') {
          return (
            time.getFullYear() +
            '年' +
            (time.getMonth() + 1) +
            '月' +
            time.getDate() +
            '日'
          )
        } else if (type === 'timestamp') {
          var date = val
            .replace('年', '-')
            .replace('月', '-')
            .replace('日', '')
          var datearr = date.split('-')
          if (datearr[1].length < 2) {
            datearr[1] = '0' + datearr[1]
          }
          if (datearr[2].length < 2) {
            datearr[2] = '0' + datearr[2]
          }
          date = datearr[0] + '-' + datearr[1] + '-' + datearr[2]
          date = new Date(date).getTime()
          return date
        }
      } else {
        return '--'
      }
    },
    valid (reg, msg) {
      const newReg = new RegExp(reg)
      const vaildReg = function (r, v, c) {
        if (v) {
          if (!newReg.test(v)) {
            c(new Error(msg))
          } else {
            c()
          }
        } else {
          c(new Error(msg))
        }
      }
      return vaildReg
    },
    // 文件生成失败展示
    ShowstateConfirm (fileName, rest) {
      this.$stateConfirm({
        title: '提示',
        message: fileName,
        show: true,
        type: '',
        messageType: 'Array'
      })
        .then(() => {
          // 用户点击确认后执行
          if (rest) {
            this.getFilelist()
          }
        })
        .catch(() => {
          // 取消或关闭
          if (rest) {
            this.getFilelist()
          }
        })
    },
    // 生成文件
    generateFile (Postdata) {
      this.api
        .postGenerate(Postdata)
        .then((res) => {
          if (res.data) {
            const fileName = this.structureFailList(res)
            if (fileName.length > 0) {
              this.ShowstateConfirm(fileName)
            } else {
              this.success('文件生成成功')
              setTimeout(() => {
                this.getFilelist('start')
              }, 1000)
              this.visible = false
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    changeValidTimeUnit (prop, data) {
      const self = this
      return function (prop, data) {
        self.selctfile.forEach((item, index) => {
          item.config.forEach((elem, indexOn) => {
            if (elem.prop === prop) {
              // elem.validTimeUnit = data
              self.$refs.recordingDialog.$data.queryParas[prop + 'validTimeUnit'] = data

              console.log(self.selctfile[index].config[indexOn])
              // self.$set(self.selctfile[index].config[indexOn], 'validTimeUnit', data)
              elem.attrs = {
                placeholder: '请输入有效期',
                maxLength: 5,
                type: 'ZS',
                validTimeUnit: data
              }
              if (elem.groupId) {
                elem.rules = [
                  {
                    required: elem.mandatory === '1' || elem.groupId,
                    message: '请选择',
                    trigger: 'change'
                  }
                ]
              } else {
                elem.rules = [
                  {
                    required: elem.mandatory === '1',
                    message: '请输入',
                    trigger: 'change'
                  }
                ]
              }

              elem.span = 8
              elem.tag = termOfValidity
              elem.on = {
                change (data) {
                  elem.grouping(
                    elem.groupId,
                    elem.productFileId + elem.displayOrder
                  )
                },
                update (data) {
                  elem.changeValidTimeUnit(elem.productFileId + elem.displayOrder, data)
                }

              }
            }
          })
        })
        self.$refs.recordingDialog.$forceUpdate()
      }
    },
    grouping (groupId, prop) {
      const self = this
      return function (groupId, prop) {
        if (groupId) {
          let newIndex = ''
          self.selctfile.forEach((item, index) => {
            if (item.config.length > 0) {
              item.config.forEach((elem) => {
                if (elem.prop === prop) {
                  newIndex = index
                }
              })
            }
          })

          if (newIndex || newIndex === 0) {
            // 获取最小数据值
            let minNum = ''
            // 同一id分组
            const groupArr = []
            self.selctfile[newIndex].config.forEach((item) => {
              if (item.prop === prop) {
                minNum = item.minNum
              }
              if (item.groupId === groupId) {
                groupArr.push(item)
              }
            })
            // 已有值数量
            let Num = 0
            // 已有值的对象
            const obj = self.$refs.recordingDialog.$data.queryParas

            for (const key in obj) {
              if (groupArr && groupArr.length > 0) {
                groupArr.forEach((item) => {
                  // console.log(item, key)
                  if (Array.isArray(obj[key])) {
                    if (obj[key].length > 0) {
                      if (key === item.prop) {
                        Num++
                      }
                    }
                  } else {
                    if (key === item.prop && obj[key]) {
                      Num++
                    }
                  }
                })
              }
            }

            if (Num - minNum >= 0) {
              // 非必填项下标
              const RequiredArr = []
              self.selctfile[newIndex].config.forEach((item) => {
                for (const key in obj) {
                  if (Array.isArray(obj[key])) {
                    if (obj[key].length > 0) {
                      if (item.prop === key) {
                        RequiredArr.push(item.prop)
                      }
                    }
                  } else {
                    if (item.prop === key && obj[key]) {
                      RequiredArr.push(item.prop)
                    }
                  }
                }
              })
              const notRequiredArr = []
              self.selctfile[newIndex].config.forEach((item, index) => {
                if (item.groupId === groupId) {
                  if (RequiredArr.indexOf(item.prop) === -1) {
                    notRequiredArr.push(index)
                  }
                }
              })

              if (notRequiredArr.length > 0) {
                notRequiredArr.forEach((item) => {
                  console.log(self.selctfile[newIndex].config[item], '222')
                  if (self.selctfile[newIndex].config[item].documentName === '股东决定') {
                    self.$set(self.selctfile[newIndex].config[item], 'rules', [
                      { required: false, message: '请选择', trigger: 'change' }
                    ])
                    self.$set(self.selctfile[newIndex].config[item].attrs, 'disabled', true)
                  } else {
                    self.$set(self.selctfile[newIndex].config[item], 'rules', [
                      { required: false, message: '请选择', trigger: 'change' }
                    ])
                  }
                  console.log(self.selctfile[newIndex].config[item], '0000')
                  const dom = self.$refs.recordingDialog.$refs.formData
                  // 清楚验证
                  if (dom && dom.length > 0) {
                    dom.forEach((item) => {
                      item.clearValidate()
                    })
                  }
                })
              }
            } else {
              self.selctfile[newIndex].config.forEach((item) => {
                if (item.groupId === groupId) {
                  if (item.documentName === '股东决定') {
                    item.attrs.disabled = false
                  }
                  item.rules = [
                    { required: true, message: '请选择', trigger: 'change' }
                  ]
                }
              })
            }
          }
        }
      }
    },
    // 生成 公共补录项配置表单
    generatePublicConfig (res, params) {
      // this.htconfig = res.data
      console.log(res, 'res', this.publicFormConfig)
      res.forEach((item) => {
        // 构建form数据

        // item.searchCompany = this.searchCompany()
        if (item.inputTypeId === 0) {
          // 文本框
          item.attrs = {
            placeholder: '请输入'
          }
          if (item.regularCode) {
            // 有正则
            if (item.regularCode === '^(*{0,20})') {
              item.rules = [
                {
                  required: item.mandatory === '1' || item.groupId,
                  message: '请输入',
                  trigger: 'blur'
                }
              ]
              item.attrs.maxlength = 100
            } else {
              item.rules = [
                {
                  required: item.mandatory === '1' || item.groupId,
                  validator: this.valid(item.regularCode, item.regularMsg),
                  trigger: 'blur'
                }
              ]
            }
          } else {
            item.rules = [
              {
                required: item.mandatory === '1' || item.groupId,
                message: '请输入',
                trigger: 'blur'
              }
            ]
          }
          item.span = 8
          item.on = {
            blur (data) {

            }
          }
        } else if (item.inputTypeId === 1) {
          // 多行文本框
          item.attrs = {
            placeholder: '请输入'
          }
          if (item.regularCode) {
            // 有正则
            if (item.regularCode === '^(*{0,20})') {
              item.rules = [
                {
                  required: item.mandatory === '1' || item.groupId,
                  message: '请输入',
                  trigger: 'blur'
                }
              ]
              item.attrs.maxlength = 20
            } else {
              item.rules = [
                {
                  required: item.mandatory === '1' || item.groupId,
                  validator: this.valid(item.regularCode, item.regularMsg),
                  trigger: 'blur'
                }
              ]
            }
          } else {
            item.rules = [
              {
                required: item.mandatory === '1' || item.groupId,
                message: '请输入',
                trigger: 'blur'
              }
            ]
          }
          item.on = {
            blur (data) {

            }
          }
          item.span = 24
        } else if (item.inputTypeId === 2) {
          const startDatePicker = {
            disabledDate (time) {
              return time.getTime() < new Date('1999-12-31')
            }
          }
          // 时间选择框
          item.attrs = {
            type: 'date',
            placeholder: '请选择',
            prefixIcon: 'el-icon-date',
            format: 'yyyy 年 MM 月 dd 日',
            'value-format': 'timestamp',
            pickerOptions: startDatePicker

          }
          if (item.groupId) {
            item.rules = [
              {
                required: item.mandatory === '1' || item.groupId,
                message: '请选择',
                trigger: 'blur'
              }
            ]
          } else {
            item.rules = [
              {
                required: item.mandatory === '1',
                message: '请选择',
                trigger: 'blur'
              }
            ]
          }

          // item.rules = [
          //   { required: false, message: '请选择', trigger: 'blur' }
          // ]
          item.span = 8
          item.tag = 'el-date-picker'
          item.on = {
            change (data) {

            }
          }
        } else if (item.inputTypeId === 3) {
          // 下拉选择框 多选
          item.attrs = {
            placeholder: '请选择',
            multiple: true,
            filterable: true,
            options: item.options,
            selectedField: ['id', 'name']
          }
          if (item.documentName === '股东决定') {
            item.attrs.multipleLimit = 1
          }

          if (item.groupId) {
            item.rules = [
              {
                required: item.mandatory === '1' || item.groupId,
                message: '请选择',
                trigger: 'change'
              }
            ]
          } else {
            item.rules = [
              {
                required: item.mandatory === '1',
                message: '请选择',
                trigger: 'change'
              }
            ]
          }

          item.span = 8
          item.tag = BaseSelect
          item.on = {

          }
        } else if (item.inputTypeId === 4) {
          // 下拉选择框 单选
          item.attrs = {
            placeholder: '请选择',
            options: item.options,
            selectedField: ['id', 'name']
          }
          if (item.documentName === '股东决定') {
            item.attrs.multipleLimit = 1
          }

          if (item.groupId) {
            item.rules = [
              {
                required: item.mandatory === '1' || item.groupId,
                message: '请选择',
                trigger: 'change'
              }
            ]
          } else {
            item.rules = [
              {
                required: item.mandatory === '1',
                message: '请选择',
                trigger: 'change'
              }
            ]
          }

          item.span = 8
          item.tag = BaseSelect
          item.on = {

          }
        } else if (item.inputTypeId === 101) {
          // 有效性 特殊类型
          item.attrs = {
            placeholder: '请输入有效期',
            maxLength: 5,
            type: 'ZS',
            validTimeUnit: item.validTimeUnit
          }
          if (item.groupId) {
            item.rules = [
              {
                required: item.mandatory === '1' || item.groupId,
                message: '请选择',
                trigger: 'change'
              }
            ]
          } else {
            item.rules = [
              {
                required: item.mandatory === '1',
                message: '请输入',
                trigger: 'change'
              }
            ]
          }

          item.span = 8
          item.tag = termOfValidity
          item.on = {
            // change (data) {
            //   item.grouping(
            //     item.groupId,
            //     item.productFileId + item.displayOrder
            //   )
            // },
            // update (data) {
            //   item.changeValidTimeUnit(item.productFileId + item.displayOrder, data)
            // }

          }
        } else if (item.inputTypeId === 5) {
          // 特殊输入框 ，公司股东
          item.attrs = {
            placeholder: '请选择',
            multiple: true,
            filterable: true,
            remote: true,
            remoteMethod: (data) => this.searchCompany(data, item.productFileId + item.displayOrder),
            options: item.options,
            selectedField: ['id', 'name']
          }
          if (item.groupId) {
            item.rules = [
              {
                required: item.mandatory === '1' || item.groupId,
                message: '请选择',
                trigger: 'change'
              }
            ]
          } else {
            item.rules = [
              {
                required: item.mandatory === '1',
                message: '请选择',
                trigger: 'change'
              }
            ]
          }
          item.span = 8
          item.tag = BaseSelect
          item.on = {

          }
        }
        this.publicFormConfig.push({
          label: item.displayName,
          prop: item.productFileId + item.displayOrder, // makeupItemId会重复 排序不会重复
          ...item
        })

        if (item.value) {
          if (item.inputTypeId === 2) {
            this.$set(
              this.$refs.recordingDialog.$data.queryParas,
              item.productFileId + item.displayOrder,
              this.formatTime(item.value, 'timestamp')
            )
            this.$nextTick(() => {
              item.on.change(this.formatTime(item.value, 'timestamp'))
            })
          } else if (item.inputTypeId === 3 || item.inputTypeId === 5) {
            const newValue = item.value.split(',')
            const newArr = []
            // 返回value 在返回选项中没有是清空
            newValue.forEach((ele, index, arr) => {
              item.options.some((key) => {
                if (ele === key.id) {
                  newArr.push(ele)
                }
              })
            })
            // 选择框 开发
            this.$set(
              this.$refs.recordingDialog.$data.queryParas,
              item.productFileId + item.displayOrder,
              newArr
            )
            this.$nextTick(() => {
              item.on.change(newArr)
            })
          } else if (item.inputTypeId === 101) {
            // 特殊类型 有效性

          } else {
            this.$set(
              this.$refs.recordingDialog.$data.queryParas,
              item.productFileId + item.displayOrder,
              item.value
            )
          }
        }
      })

      console.log(this.publicFormConfig, 'this.publicFormConfig')
    },
    // 远程搜索，匹配选项
    searchCompany (data, prop) {
      const self = this
      self.selctfile.forEach((item, index) => {
        item.config.forEach((elem, indexOn) => {
          if (elem.prop === prop) {
            if (data) {
              fileListApi.getCompanyShareholdersData({ retrieveStr: data }).then(res => {
                self.$set(self.selctfile[index].config[indexOn].attrs, 'options', res.data)
                self.$refs.recordingDialog.$forceUpdate()
              })
            } else {
              self.$set(self.selctfile[index].config[indexOn].attrs, 'options', self.selctfile[index].config[indexOn].options)
              self.$refs.recordingDialog.$forceUpdate()
            }
          }
        })
      })
    },
    // 生成 补录项表单配置
    generateConfig (res, params) {
      this.htconfig = res
      res.forEach((item) => {
        this.selctfile.forEach((elem, index) => {
          if (elem.documentId === item.productFileId) {
            // 构建form数据
            item.documentName = elem.documentName
            item.grouping = this.grouping()
            item.changeValidTimeUnit = this.changeValidTimeUnit()
            // item.searchCompany = this.searchCompany()
            if (item.inputTypeId === 0) {
              // 文本框
              item.attrs = {
                placeholder: '请输入'
              }
              if (item.regularCode) {
                // 有正则
                if (item.regularCode === '^(*{0,20})') {
                  item.rules = [
                    {
                      required: item.mandatory === '1' || item.groupId,
                      message: '请输入',
                      trigger: 'blur'
                    }
                  ]
                  item.attrs.maxlength = 100
                } else {
                  item.rules = [
                    {
                      required: item.mandatory === '1' || item.groupId,
                      validator: this.valid(item.regularCode, item.regularMsg),
                      trigger: 'blur'
                    }
                  ]
                }
              } else {
                item.rules = [
                  {
                    required: item.mandatory === '1' || item.groupId,
                    message: '请输入',
                    trigger: 'blur'
                  }
                ]
              }
              item.span = 8
              item.on = {
                blur (data) {
                  item.grouping(
                    item.groupId,
                    item.productFileId + item.displayOrder
                  )
                }
              }
            } else if (item.inputTypeId === 1) {
              // 多行文本框
              item.attrs = {
                placeholder: '请输入'
              }
              if (item.regularCode) {
                // 有正则
                if (item.regularCode === '^(*{0,20})') {
                  item.rules = [
                    {
                      required: item.mandatory === '1' || item.groupId,
                      message: '请输入',
                      trigger: 'blur'
                    }
                  ]
                  item.attrs.maxlength = 20
                } else {
                  item.rules = [
                    {
                      required: item.mandatory === '1' || item.groupId,
                      validator: this.valid(item.regularCode, item.regularMsg),
                      trigger: 'blur'
                    }
                  ]
                }
              } else {
                item.rules = [
                  {
                    required: item.mandatory === '1' || item.groupId,
                    message: '请输入',
                    trigger: 'blur'
                  }
                ]
              }
              item.on = {
                blur (data) {
                  item.grouping(
                    item.groupId,
                    item.productFileId + item.displayOrder
                  )
                }
              }
              item.span = 24
            } else if (item.inputTypeId === 2) {
              const startDatePicker = {
                disabledDate (time) {
                  return time.getTime() < new Date('1999-12-31')
                }
              }
              // 时间选择框
              item.attrs = {
                type: 'date',
                placeholder: '请选择',
                prefixIcon: 'el-icon-date',
                format: 'yyyy 年 MM 月 dd 日',
                'value-format': 'timestamp',
                pickerOptions: startDatePicker

              }
              if (item.groupId) {
                item.rules = [
                  {
                    required: item.mandatory === '1' || item.groupId,
                    message: '请选择',
                    trigger: 'blur'
                  }
                ]
              } else {
                item.rules = [
                  {
                    required: item.mandatory === '1',
                    message: '请选择',
                    trigger: 'blur'
                  }
                ]
              }

              // item.rules = [
              //   { required: false, message: '请选择', trigger: 'blur' }
              // ]
              item.span = 8
              item.tag = 'el-date-picker'
              item.on = {
                change (data) {
                  item.grouping(
                    item.groupId,
                    item.productFileId + item.displayOrder
                  )
                }
              }
            } else if (item.inputTypeId === 3) {
              // 下拉选择框 多选
              item.attrs = {
                placeholder: '请选择',
                multiple: true,
                filterable: true,
                options: item.options,
                selectedField: ['id', 'name']
              }
              if (item.documentName === '股东决定') {
                item.attrs.multipleLimit = 1
              }

              if (item.groupId) {
                item.rules = [
                  {
                    required: item.mandatory === '1' || item.groupId,
                    message: '请选择',
                    trigger: 'change'
                  }
                ]
              } else {
                item.rules = [
                  {
                    required: item.mandatory === '1',
                    message: '请选择',
                    trigger: 'change'
                  }
                ]
              }

              item.span = 8
              item.tag = BaseSelect
              item.on = {
                change (data) {
                  item.grouping(
                    item.groupId,
                    item.productFileId + item.displayOrder
                  )
                }
              }
            } else if (item.inputTypeId === 4) {
              // 下拉选择框 单选
              item.attrs = {
                placeholder: '请选择',
                options: item.options,
                selectedField: ['id', 'name']
              }
              if (item.documentName === '股东决定') {
                item.attrs.multipleLimit = 1
              }

              if (item.groupId) {
                item.rules = [
                  {
                    required: item.mandatory === '1' || item.groupId,
                    message: '请选择',
                    trigger: 'change'
                  }
                ]
              } else {
                item.rules = [
                  {
                    required: item.mandatory === '1',
                    message: '请选择',
                    trigger: 'change'
                  }
                ]
              }

              item.span = 8
              item.tag = BaseSelect
              item.on = {
                change (data) {
                  item.grouping(
                    item.groupId,
                    item.productFileId + item.displayOrder
                  )
                }
              }
            } else if (item.inputTypeId === 101) {
              // 有效性 特殊类型
              item.attrs = {
                placeholder: '请输入有效期',
                maxLength: 5,
                type: 'ZS',
                validTimeUnit: item.validTimeUnit
              }
              if (item.groupId) {
                item.rules = [
                  {
                    required: item.mandatory === '1' || item.groupId,
                    message: '请选择',
                    trigger: 'change'
                  }
                ]
              } else {
                item.rules = [
                  {
                    required: item.mandatory === '1',
                    message: '请输入',
                    trigger: 'change'
                  }
                ]
              }

              item.span = 8
              item.tag = termOfValidity
              item.on = {
                // change (data) {
                //   item.grouping(
                //     item.groupId,
                //     item.productFileId + item.displayOrder
                //   )
                // },
                update (data) {
                  item.changeValidTimeUnit(item.productFileId + item.displayOrder, data)
                }

              }
            } else if (item.inputTypeId === 5) {
              // 特殊输入框 ，公司股东
              item.attrs = {
                placeholder: '请选择',
                multiple: true,
                filterable: true,
                remote: true,
                remoteMethod: (data) => this.searchCompany(data, item.productFileId + item.displayOrder),
                options: item.options,
                selectedField: ['id', 'name']
              }
              if (item.groupId) {
                item.rules = [
                  {
                    required: item.mandatory === '1' || item.groupId,
                    message: '请选择',
                    trigger: 'change'
                  }
                ]
              } else {
                item.rules = [
                  {
                    required: item.mandatory === '1',
                    message: '请选择',
                    trigger: 'change'
                  }
                ]
              }
              item.span = 8
              item.tag = BaseSelect
              item.on = {
                change (data) {
                  item.grouping(
                    item.groupId,
                    item.productFileId + item.displayOrder
                  )
                }

              }
            }

            elem.config.push({
              label: item.displayName,
              prop: item.productFileId + item.displayOrder, // makeupItemId会重复 排序不会重复
              ...item
            })
            if (item.value) {
              if (item.inputTypeId === 2) {
                this.$set(
                  this.$refs.recordingDialog.$data.queryParas,
                  item.productFileId + item.displayOrder,
                  this.formatTime(item.value, 'timestamp')
                )
                this.$nextTick(() => {
                  item.on.change(this.formatTime(item.value, 'timestamp'))
                })
              } else if (item.inputTypeId === 3 || item.inputTypeId === 5) {
                const newValue = item.value.split(',')
                const newArr = []
                // 返回value 在返回选项中没有是清空
                newValue.forEach((ele, index, arr) => {
                  item.options.some((key) => {
                    if (ele === key.id) {
                      newArr.push(ele)
                    }
                  })
                })
                // 选择框 开发
                this.$set(
                  this.$refs.recordingDialog.$data.queryParas,
                  item.productFileId + item.displayOrder,
                  newArr
                )
                this.$nextTick(() => {
                  item.on.change(newArr)
                })
              } else if (item.inputTypeId === 101) {
                // 特殊类型 有效性

              } else {
                this.$set(
                  this.$refs.recordingDialog.$data.queryParas,
                  item.productFileId + item.displayOrder,
                  item.value
                )
              }
            }
          }
        })
      })

      this.selctfile.forEach((item) => {
        var newConfigArr = this.sortClass(item.config, 'groupGysId')

        newConfigArr.forEach((elem) => {
          item.childen.push(elem)
        })
      })
      // 电子章存在重复文件 所以补录项去重
      this.selctfile = this.unique(this.selctfile)
      this.visible = true
    },
    // 数组去重
    unique (arr) {
      const newAll = []
      let documentId = ''
      arr.forEach((item, index) => {
        if (index === 0) {
          newAll.push(item)
          documentId = item.documentId
        } else {
          if (documentId !== item.documentId) {
            newAll.push(item)
            documentId = item.documentId
          }
        }
      })
      return newAll
    },
    // 查询生成合同文件配置项
    async getHtgCfgitem (data) {
      if (this.statusCode.indexOf('WBF') !== -1) {
        return this.warning('稳保付合同文件当前不能系统生成，请手动上传。')
      }
      // 构造参数
      this.selctfile = []
      this.htconfig = []
      this.publicFormConfig = []
      let params = {}
      if (!data) {
        if (this.selectedRows.length === 0) {
          this.warning('请选择合同文件')
          return
        }
        let state = true
        // 获取已选中的列 构造参数
        this.selectedRows.forEach((value, index) => {
          value.config = []
          value.childen = []
          this.selctfile.push(value)
          params.businessId = value.businessId || this.$route.query.businessId
          if (!value.documentId) {
            state = false
          }
          if (index === 0) {
            params.documentIds = value.documentId
            params.documentNames = value.documentName
          } else {
            params.documentIds = params.documentIds + ',' + value.documentId
            params.documentNames =
              params.documentNames + ',' + value.documentName
          }
        })
        if (!state) {
          return this.warning('请先选择文件材料题名')
        }
        // 基础资料文件类型数据
        this.$refs.baseTableData.tableData.forEach(ele => {
          let arr = params.documentIds.split(',')
          // 判断数组中是否存在
          if(!arr.includes(ele.documentId) && ele.documentId) {
            params.documentIds = params.documentIds + ',' + ele.documentId
            params.documentNames = params.documentNames + ',' + ele.documentName
          }
        })
      } else {
        // 单个文件构造参数
        if (!data.documentId) {
          return this.warning('请先选择文件材料题名')
        }
        console.log(data)
        data.config = []
        data.childen = []
        this.selctfile = [data]
        params = {
          businessId: data.businessId || this.$route.query.businessId,
          documentIds: data.documentId,
          documentNames: data.documentName,
          documentType: data.documentType,
          isAdd: data.isAdd
        }
      }
      // 获取配置项
      const res = await this.api.gethtconfig(params)
      if (res.data) {
        // 构造补录项表单配置
        this.generateConfig(res.data.fileAddItems, params)
        this.generatePublicConfig(res.data.commonAddItems, params)
      }
    },
    // 构造 保存 提交的参数
    structurePostData (type) {
      let data = ''
      if (type === 'save') {
        data = []
      } else {
        data = {
          additemInfos: [],
          businessId: this.$route.query.businessId,
          documentId: [],
          documentName: [],
          finFileListInfoDTO: [],
          batchNum: Storage.getLocal('contractInfo').batchNum || null
        }

        this.selctfile.forEach((item) => {
          data.documentId.push(item.documentId)
          data.documentName.push(item.documentName)
          if (item.isAdd) {
            data.finFileListInfoDTO.push({
              businessId: this.$route.query.businessId,
              documentId: item.documentId,
              documentIds: item.documentIds.split(','),
              documentName: item.documentName,
              documentType: item.documentType
            })
          }
        })
        // 基础资料文件类型数据
        // this.$refs.baseTableData.tableData.forEach(ele => {
        //   let arr = data.documentId
        //   if(!arr.includes(ele.documentId) && ele.documentId) {
        //     data.documentId.push(ele.documentId)
        //     data.documentName.push(ele.documentName)
        //   }
        // })
      }

      const obj = this.$refs.recordingDialog.$data.queryParas
      this.publicFormConfig.forEach((item) => {
        for (var key in obj) {
          if (item.prop === key) {
            if (item.inputTypeId === 2) {
              if (type === 'save') {
                // 保存
                data.push({
                  ...item,
                  businessId: this.$route.query.businessId,
                  value: this.formatTime(obj[key], 'yyyy 年 MM 月 dd 日'),
                  documentId: item.productFileId
                })
              } else {
                // 提交
                data.additemInfos.push({
                  ...item,
                  value: this.formatTime(
                    this.$refs.recordingDialog.$data.queryParas[key],
                    'yyyy 年 MM 月 dd 日'
                  ),
                  documentId: item.productFileId
                })
              }
            } else if (item.inputTypeId === 3 || item.inputTypeId === 5) {
              // 数据中传入股东名字
              // const extra = obj[key].map(ele => {
              //   let text = ''
              //   item.options.forEach(eles => {
              //     if (ele === eles.id) {
              //       text = eles.name
              //     }
              //   })
              //   return text
              // })
              if (type === 'save') {
                // 保存
                data.push({
                  ...item,
                  businessId: this.$route.query.businessId,
                  value: obj[key].join(','),
                  // extra: extra.join( ','),
                  documentId: item.productFileId
                })
              } else {
                // 提交
                data.additemInfos.push({
                  ...item,
                  value: obj[key].join(','),
                  // extra: extra.join(','),
                  documentId: item.productFileId
                })
              }
            } else {
              if (type === 'save') {
                data.push({
                  ...item,
                  businessId: this.$route.query.businessId,
                  value: obj[key],
                  documentId: item.productFileId,
                  validTimeUnit: obj[key + 'validTimeUnit']
                })
                console.log(item, obj)
              } else {
                data.additemInfos.push({
                  ...item,
                  value: this.$refs.recordingDialog.$data.queryParas[key],
                  documentId: item.productFileId,
                  validTimeUnit: obj[key + 'validTimeUnit']
                })
              }
            }
          }
        }
      })
      this.htconfig.forEach((item) => {
        for (var key in obj) {
          if (item.productFileId + item.displayOrder === key) {
            if (item.inputTypeId === 2) {
              if (type === 'save') {
                // 保存
                data.push({
                  ...item,
                  businessId: this.$route.query.businessId,
                  value: this.formatTime(obj[key], 'yyyy 年 MM 月 dd 日'),
                  documentId: item.productFileId
                })
              } else {
                // 提交
                data.additemInfos.push({
                  ...item,
                  value: this.formatTime(
                    this.$refs.recordingDialog.$data.queryParas[key],
                    'yyyy 年 MM 月 dd 日'
                  ),
                  documentId: item.productFileId
                })
              }
            } else if (item.inputTypeId === 3 || item.inputTypeId === 5) {
              // 数据中传入股东名字
              // const extra = obj[key].map(ele => {
              //   let text = ''
              //   item.options.forEach(eles => {
              //     if (ele === eles.id) {
              //       text = eles.name
              //     }
              //   })
              //   return text
              // })
              if (type === 'save') {
                // 保存
                data.push({
                  ...item,
                  businessId: this.$route.query.businessId,
                  value: obj[key].join(','),
                  // extra: extra.join( ','),
                  documentId: item.productFileId
                })
              } else {
                // 提交
                data.additemInfos.push({
                  ...item,
                  value: obj[key].join(','),
                  // extra: extra.join(','),
                  documentId: item.productFileId
                })
              }
            } else {
              if (type === 'save') {
                data.push({
                  ...item,
                  businessId: this.$route.query.businessId,
                  value: obj[key],
                  documentId: item.productFileId,
                  validTimeUnit: obj[key + 'validTimeUnit']
                })
                console.log(item, obj)
              } else {
                data.additemInfos.push({
                  ...item,
                  value: this.$refs.recordingDialog.$data.queryParas[key],
                  documentId: item.productFileId,
                  validTimeUnit: obj[key + 'validTimeUnit']
                })
              }
            }
          }
        }
      })
      return data
    },
    // 构造生成失败文件列表
    structureFailList (res) {
      const fileName = []
      res.data.forEach((item, index) => {
        if (item.message) {
          const obj = {
            label: item.documentName,
            message: item.message
          }
          fileName.push(obj)
        }
      })
      return fileName
    },
    // 保存合同文件
    handHtSave () {
      // 构造参数
      const data = this.structurePostData('save')
      if (Array.isArray(data.additemInfos)) {
        data.additemInfos.forEach((item) => {
          item.options = []
        })
      }
      this.api
        .setCfgitem(data)
        .then((res) => {
          this.success('保存成功')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 提交合同文件
    handHtCommit () {
      // 构造参数
      const data = this.structurePostData('submit')

      if (Array.isArray(data.additemInfos)) {
        data.additemInfos.forEach((item) => {
          item.options = []
        })
      }
      this.api
        .postGenerate(data)
        .then((res) => {
          if (res.data) {
            const fileName = this.structureFailList(res)
            if (fileName.length > 0) {
              this.ShowstateConfirm(fileName, true)
            } else {
              this.success('文件生成成功')
              setTimeout(() => {
                this.getFilelist('start')
              }, 1000)
              this.visible = false
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 申请意见保存
    onContractSave () {
      const data = {
        businessId: this.$route.query.businessId,
        isfApproval: false,
        reviewOpinion: this.form.desc,
        reviewUserId: this.form.reviewUserId,
        createType: Storage.getLocal('contractInfo').createType || null,
        batchNum: Storage.getLocal('contractInfo').batchNum || null
      }
      this.api.contractSubmit(data).then((res) => {
        this.success('保存成功')
      })
    },
    // 申请意见提交
    onContractSubmit () {
      // 判断电子章签署人是否已经完成
      // if (!this.chosePass) {
      //   this.warning('请完善电子章签署人信息')
      //   return false
      // }
      const data = {
        businessId: this.$route.query.businessId,
        isfApproval: true,
        reviewOpinion: this.form.desc,
        reviewUserId: this.form.reviewUserId,
        createType: Storage.getLocal('contractInfo').createType || null,
        batchNum: Storage.getLocal('contractInfo').batchNum || null
      }
      let pass = true
      let documentName = ''
      if (this.tableData) {
        this.tableData.map((item) => {
          if (item.fileListDetailVOS.length === 0) {
            pass = false
            documentName = item.documentName + '文件不能为空'
          }
          // 后端未做
          // if (!item.sealAdminId) {
          //   pass = false
          //   documentName = '请选择' + item.documentName + '文件的电子章签署人'
          // }
        })
        if (!pass) {
          this.warning(documentName)
          return
        }
        // 必须有一个需要盖章
        // const isSignFalge = this.tableData.some(item => item.isSign === '1')
        // if (!isSignFalge) {
        //   this.warning('必须有一个文件类型，为需要盖章')
        //   return false
        // }
      }
      this.$refs.fromdata.validate((valid) => {
        if (valid) {
          this.api.contractSubmit(data).then((res) => {
            if (res.data) {
              this.success('提交成功')
              this.$router.push({ path: this.tags.path })
            }
          })
        }
      })
    },
    // 获取补充文件材料提名
    getFileDocument () {
      const params = { businessId: this.$route.query.businessId, batchNum: Storage.getLocal('contractInfo').batchNum || null, documentType: '50' }
      console.log(params, 'params')
      replenishApi
        .signNotdeploy(params)
        .then((res) => {
          const arr = []
          res.data.forEach((ele) => {
            let state = true
            this.tableData.forEach((item) => {
              // 过滤已经存在的文件材料
              if (ele.documentId === item.documentId) {
                state = false
              }
            })
            if (state) {
              arr.push(ele)
            }
          })
          this.options = arr
          this.tableData.forEach((item) => {
            if (!item.option || item.option.length === 0) {
              item.option = arr
            }
          })
        })
    },
    // 新增合同文件
    addFile () {
      // this.getFileDocument()
      this.tableData.push({
        addtype: true,
        documentName: '',
        documentId: '',
        fileId: '0',
        fileListDetailVOS: [],
        option: [],
        isSign: ''
      })
      this.getFileDocument()
      // 记录用户已新增的合同文件数量
      Storage.setSession('fileDataList', this.tableData)
    },
    // 查询标准合同文件清单
    getFilelist (type) {
      const businessId = this.$route.query.businessId
      replenishApi
        .signList({ businessId: businessId, isContract: '1', batchNum: Storage.getLocal('contractInfo').batchNum || null })
        .then((res) => {
          if (type === 'start') {
            this.getFileDocument()
          }
          this.canSubmitFlag = res.data.canSubmitFlag
          // this.tableData = res.data.contractsVO ? res.data.contractsVO : []
          this.tableData = this.tableRegroup(res.data.contractsVO, '50') // 合同文件
          this.baseTableData = this.tableRegroup(res.data.contractsVO, '20') // 基础资料
          console.log(this.baseTableData, '基础资料组装后')
          // if (this.tableData) {
          //   this.tableData.forEach(item => {
          //     if (item.isSign === '1') {
          //       this.$set(item, 'isSign', true)
          //     } else {
          //       this.$set(item, 'isSign', false)
          //     }
          //   })
          // }
          // if (this.suppliersSealType === 1) {
          this.tableData = this.filterData(
            this.tableData,
            'documentName',
            'documentNameRowSpan'
          )
          // }
          // 后端未做
          // 电子章时 电子签章人 选项只有一位时 禁用选择
          // if (res.data.contractsVO) {
          //   res.data.contractsVO.forEach((item, index) => {
          //     if (item.sealAdmins && item.sealAdmins.length === 1) {
          //       item.sealAdminId = item.sealAdmins[0].keyId
          //     }
          //   })
          // }
          // 股东会决议 添加 selectIndex 判断是否为根元素
          let num = 0
          this.sealAdmins = [[]]
          this.tableData.forEach((item) => {
            if (item.selectFlag) {
              num++
              item.selectIndex = num
              this.sealAdmins.push(item.sealAdmins)
            }
          })
          this.tableData.forEach((item) => {
            if (item.selectFlag && item.sealAdminId) {
              this.deleteArray(item.sealAdminId, item.selectIndex)
            }
          })
          // 更换签章人员的类别之后，判断是否当前选择人员是否还存在
          this.tableData.forEach((item) => {
            let flag = false
            if (item.sealAdminId && item.sealAdmins) {
              item.sealAdmins.forEach((elem) => {
                if (elem.keyId === item.sealAdminId) {
                  flag = true
                }
              })
            }
            if (!flag) {
              item.sealAdminId = ''
            }
          })
          this.fileData = res.data.othersVO ? res.data.othersVO : []
          // 获取用户已新增的补充文件数量
          const fileDataList = Storage.getSession('fileDataList')
          if (fileDataList) {
            const length = fileDataList.length - this.fileData.length
            if (this.fileData.length < fileDataList.length) {
              for (var i = 0; i < length; i++) {
                this.fileData.push({ addtype: true })
              }
            }
          }
          this.finReviewOpinionInfoVO = res.data.finReviewOpinionInfoVO
          if (res.data.finReviewOpinionInfo) {
            this.$set(this.form, 'desc', res.data.finReviewOpinionInfo.reviewOpinion ? res.data.finReviewOpinionInfo.reviewOpinion : '')
            this.$set(this.form, 'reviewUserId', res.data.finReviewOpinionInfo.reviewUserId ? res.data.finReviewOpinionInfo.reviewUserId : '')
          }
        })
    },
    //表格数组重组
    tableRegroup (data, type) { // type // 50 合同文件  20 基础资料文件
      let arr = []
      if(data && data.length > 0) {
        data.forEach(ele => {
          if(ele.documentType === type) {
            arr.push(ele)
          }
        })
      }
      // 基础资料文件数据组装
      if(type === '20') {
        let arrs = []
        arr.forEach(ele => {
          let gysList = []
          // 获取当前文件类型下存在几个供应商
          if(ele.fileListDetailVOS && ele.fileListDetailVOS.length > 0) {
            ele.fileListDetailVOS.forEach(ele => {
              if(!gysList.includes(ele.signerId)) {
                gysList.push(ele.signerId)
              }
            })
          }else {
            ele.addFiles = true
            arrs.push(ele)
          }
          // 根据供应商数量，增加几行数据
          gysList.forEach(item => {
            let eles = JSON.parse(JSON.stringify(ele))
            this.$set(eles, 'fileListDetailVOS', [])
            if(ele.fileListDetailVOS && ele.fileListDetailVOS.length > 0) {
              ele.fileListDetailVOS.forEach(element => {
                if(item === element.signerId) {
                  eles.fileListDetailVOS.push(element)
                }
              })
            }
            const items = eles.fileListDetailVOS
            if(items && items.length > 0) {
              eles.signName = items[0].signName
              eles.signerId = items[0].signerId
              eles.isSign = items[0].isSign ? items[0].isSign : eles.isSign
            }
            arrs.push(eles)
          })
          arr = arrs
        })
      }
      return arr
    },
  },
  mounted () {
    this.resultValidFlag = Storage.getLocal('contractInfo').resultValidFlag
    this.capitalSideName = Storage.getLocal('contractInfo').capitalSideName
    // this.suppliersSealType = Storage.getLocal('contractInfo').suppliersSealType
    // if (this.suppliersSealType === 1) {
    this.chosePass = false
    // }
    this.getRiskManagerOptions()
    this.getFilelist('start')
  },
  beforeDestroy () {
    sessionStorage.removeItem('fileDataList')
  }
}
</script>
<style lang="scss" scoped>
.tipsDiv {
  color: #2862E7;
  position: absolute;
  top: 3px;
  left: 96px;
  font-size: 20px;
  cursor: pointer;
  .iconfont {
    font-size: 20px;
  }
}
.makecontent {
  height: calc(100% - 55px);
  overflow-y: scroll;
}
/deep/ textarea {
  width: 100%;
  resize: none;
  border: 1px solid #2862E7;
  outline-color: #2862E7;
}
.table {
  /deep/.upload-demo {
    margin: 0 !important;
    display: inline-block;
    &:hover {
      color: #2862E7 !important;
    }
    .el-upload--text {
      .iconfont {
        height: 28px;
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          border-radius: 50%;
          background: #EBF1FF;
          color: #2862E7 !important;
        }
      }
    }
  }
}
.addPositionName {
  position: absolute;
  top: 0;
  right: 0;
  .addBox {
    width: 0;
    height: 0;
    border-top: 25px solid #2862E7;
    border-left: 25px solid transparent;
    i {
      cursor: pointer;
      color: #fff;
      font-size: 13px;
      position: absolute;
      right: 0px;
      top: 2px;
    }
  }
}


.contractFileMake {
  display: flex;
  height: calc(100% - 40px);
  .addbtn {
    padding: 7px 0px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    .addbutton {
      background: #4A7CF0;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      &:hover {
        background-color: #6996FF;
      }
    }
  }
  .item {
    padding: 0 11px;
  }
  .titleNameSlot {
    margin: 10px 0;
    position: relative;
  }
  .makeTitle {
    /deep/ button {
      display: flex;
      align-items: center;
      height: 34px;
      .iconshengcheng {
        font-size: 20px;
      }
    }
    span {
      margin-right: 30px;
    }
  }
  .left {
    flex: 1;
    margin-right: 8px;
    background: #fff;
  }
  .right {
    width: 449px;
    min-width: 449px;
    background: #fff;
    overflow-y: auto;
    .submit {
      display: flex;
      justify-content: center;
    }
    .title {
      margin-bottom: 20px;
    }
    /deep/.iconzhongxinshangchuan {
      margin: 0;
    }
  }
}
// /deep/ .el-tooltip{
//   width: 100% !important;
// }
.updataSupply {
  display: flex;
}

/deep/ .el-form-item__content {
    height: auto !important;
  }
</style>
